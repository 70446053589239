import React, { useEffect, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getApiEndpoint } from '../../utils/endpoints'

const Content = ({ location }) => {
  const { t } = useTranslation()
  const [data, setData] = useState({formulaire: "Migrate"})

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.hasOwnProperty("checked") ? e.target.checked : e.target.value
    })
  }

  return (
    <>
      <section className={'visuel_identity'}>
        <div className={'container'}>
          <form
            name={'Migrate'}
            onSubmit={async (e) => {
              e.preventDefault();
              await fetch(`${getApiEndpoint()}/quintyss-contact`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              });
              window.location = location.pathname + (location.pathname.endsWith("/") ? '' : '/') + '?success=true';
              return false;
            }}
          >
            <div className="contact__form no-border  ">
              <div
                className="form-group mb-5 input-lg"
                style={{ paddingTop: '50px' }}
              >
                <h4 className="functionnality__title section-title">
                  {t('page_migrate_form_label_1')}
                </h4>
                <label htmlFor="lien_du_site">
                  {t('page_migrate_form_label_1_1')}
                </label>
                <input
                  type="text"
                  onChange={updateData}
                  name="lien_du_site"
                  required={true}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-5  ">
                <h4 className="functionnality__title section-title">
                  {t('page_migrate_form_label_2')}
                </h4>
                <label htmlFor="description">
                  {t('page_migrate_form_label_2_1')}
                </label>
                <textarea
                  id="link"
                  name="description"
                  onChange={updateData}
                  required={true}
                  className="form-control"
                  rows="4"
                />
              </div>
              <div className="form-group  mb-5">
                <h4 className="functionnality__title section-title">
                  {t('page_migrate_form_label_3')}
                </h4>
                <span className="mb-3">{t('page_migrate_form_label_3_1')}</span>
                <div className="mt-3">
                  <label className="flex">
                    <input
                      type="checkbox"
                      name={t('page_migrate_form_label_3_checkbox_1')}
                      onChange={updateData}
                      id="page_migrate_form_label_3_checkbox_1"
                      value={t('page_migrate_form_label_3_checkbox_1')}
                    />
                    <span>{t('page_migrate_form_label_3_checkbox_1')}</span>
                  </label>
                  <label className="flex">
                    <input
                      type="checkbox"
                      name={t('page_migrate_form_label_3_checkbox_2')}
                      onChange={updateData}
                      id="page_migrate_form_label_3_checkbox_2"
                      value={t('page_migrate_form_label_3_checkbox_2')}
                    />
                    <span>{t('page_migrate_form_label_3_checkbox_2')}</span>
                  </label>
                  <label className="flex">
                    <input
                      type="checkbox"
                      name={t('page_migrate_form_label_3_checkbox_3')}
                      onChange={updateData}
                      id="page_migrate_form_label_3_checkbox_3"
                      value={t('page_migrate_form_label_3_checkbox_3')}
                    />
                    <span>{t('page_migrate_form_label_3_checkbox_3')}</span>
                  </label>
                  <label className="flex">
                    <input
                      type="checkbox"
                      onChange={updateData}
                      name={t('page_migrate_form_label_3_checkbox_4')}
                      id="page_migrate_form_label_3_checkbox_4"
                      value={t('page_migrate_form_label_3_checkbox_4')}
                    />
                    <span>{t('page_migrate_form_label_3_checkbox_4')}</span>
                  </label>
                </div>
              </div>
              <div className="form-group input-lg mb-5">
                <h4 className="contact__title section-title">
                  {t('page_migrate_form_label_5')}
                </h4>
                <label htmlFor="lastName">{t('constant_label_lastname')}</label>
                <input
                  type="text"
                  id="lastName"
                  name="nom"
                  onChange={updateData}
                  required={true}
                  className="form-control mb-3"
                />
                <label htmlFor="firstName">
                  {t('constant_label_firstname')}
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="prenom"
                  required={true}
                  onChange={updateData}
                  className="form-control mb-3"
                />
                <label htmlFor="email">{t('constant_label_email')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={updateData}
                  required={true}
                  className="form-control mb-3"
                />
                <label htmlFor="phone">{t('constant_label_tel')}</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={updateData}
                  required={true}
                  className="form-control mb-3"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: '10px' }}
              >
                {t('constant_send')}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default Content
